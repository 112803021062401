import '../styles/slider.css'
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, PageProps } from 'gatsby'
import { theme } from 'src/theme/ThemeProvider'
import { IPageContext } from '@/types/pages'
import Slider from 'react-slick'
import { Grid, Typography, Box, Divider } from '@mui/material'
import NewTextModal from '@/components/elements/new-text-modal'
import { Oval } from 'react-loader-spinner'
import { useIsMobile } from '@/utils/hooks'

import {
  // PricingPageQuery,
  Strapi__Component_Meta_Metadata,
  Strapi_Global,
} from 'gatsby-graphql'

import ThemeTypeContext from '@/components/themeTypeContext'
import { useLocalizePage } from '@/utils/hooks'

const CustomPrevArrow = (props: any) => (
  <div
    className={props.className}
    onClick={props.onClick}
    style={{
      background: '#F3504F',
      height: '36px',
      width: '36px',
      borderRadius: '25px',
      border: '4px solid rgba(255, 255, 255, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'initial',
      bottom: '-56px',
      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.4)',
    }}
  >
    <img className="left-arrow" src="/images/left-arrow.svg" alt="vertical line" width={8} height={14} />
  </div>
)

const CustomNextArrow = (props: any) => (
  <div
    className={props.className}
    onClick={props.onClick}
    style={{
      background: '#F3504F',
      height: '36px',
      width: '36px',
      borderRadius: '25px',
      border: '4px solid rgba(255, 255, 255, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'initial',
      bottom: '-56px',
      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.4)',
    }}
  >
    <img className="right-arrow" src="/images/right-arrow.svg" alt="vertical line" width={8} height={14} />
  </div>
)
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1.3,
  centerMode: true,
  centerPadding: '25%',
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '15%',
      },
    },
    {
      breakpoint: 767,
      settings: 'unslick',
    },
  ],
}

const Roadmap: React.FC<PageProps<any>> = (props) => {
  const { data } = props
  const pageContext = props?.pageContext as IPageContext

  const global = data?.strapiGlobal as Strapi_Global
  const StepLabelBorderRadius = '3px'
  const StepLabelTriangleSize = '10px'

  const { setTheme } = useContext(ThemeTypeContext)
  setTheme('core')
  const location = props?.location
  const isMobile = useIsMobile();

  const strapiRoadmap = data?.strapiRoadmap
  const metaData = strapiRoadmap?.MetaData as Strapi__Component_Meta_Metadata

  useLocalizePage(pageContext, location)

  const [modalStatus, setModalStatus] = useState(false)
  const [mobileLoading, setMobileLoading] = useState(true)
  const [modalContent, setModalContent] = useState({ title: '', content: '' })

  const HandleModal = (slideIndex: any, itemIndex: any) => {
    const slideItem = strapiRoadmap?.Slide[slideIndex]?.List[itemIndex]?.PopupData
    if (slideItem) {
      setModalContent({
        title: slideItem?.Heading,
        content: slideItem?.Content?.data?.Content,
      })
      console.log('content changed', modalContent)

      setModalStatus(true)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setMobileLoading(false)  
    }, (window.innerWidth  < 769) ? 3000 : 1000);    
  }, [])
  return (
    <>
      <SEO metaData={metaData} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, ...metaData }} theme={theme}>
        <Box
          sx={{
            paddingBottom: { xs: '0', md: '81px' },
          }}
        >
          <Box
            className="pb-0"
            sx={{
              paddingBottom: { xs: '105px' },
            }}
          >
            <Grid container sx={{ textAlign: 'center', marginBottom: '80px' }}>
              {strapiRoadmap?.SubHeading && (
                <Grid item xs={12} sx={{ display: { md: 'block' } }}>
                  <Typography sx={{ fontSize: 20 }}> {strapiRoadmap?.SubHeading} </Typography>
                </Grid>
              )}
              {strapiRoadmap?.Heading && (
                <Grid item xs={12} sx={{ paddingBottom: { xs: '24px' } }}>
                  <Typography sx={{ fontSize: { xs: 30, md: 40 }, fontWeight: 700 }}>
                    {' '}
                    {strapiRoadmap?.Heading}{' '}
                  </Typography>
                </Grid>
              )}
              {strapiRoadmap?.Description?.data?.Description && (
                <Grid item xs={12} sx={{ maxWidth: { xs: '614px', margin: 'auto' } }}>
                  <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                    {strapiRoadmap?.Description?.data?.Description}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {mobileLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingTop: { md: 8, xs: 4 },
                    paddingBottom: { md: 16, xs: 8 },
                  }}
                >
                <Oval color="#ffffff" />
              </Box>
              ): 
              /* {slider start here} */
              strapiRoadmap?.Slide?.length > 0 && (
                <Box
                  sx={{
                    position: 'relative',
                    '&::after': {
                      content: "''",
                      position: 'absolute',
                      borderColor: '#EFCC0D',
                      width: '100%',
                      bottom: '39px',
                      left: '0',
                      height: '1px',
                      background:
                        'repeating-linear-gradient(90deg,#EFCC0D,#EFCC0D 6px,transparent 6px,transparent 12px)',
                      display: { xs: 'none', md: 'block' },
                    },
                  }}
                >
                  <Slider {...settings} className="slider_wrap">
                    {strapiRoadmap?.Slide?.map((slide, slideIndex) => (
                      <div className="slider" key={'item' + slideIndex}>
                        <Grid
                          className="s_margin"
                          item
                          xs={12}
                          sx={{
                            paddingBottom: { xs: '10px' },
                            marginBottom: { xs: '80px' },
                            display: 'flex',
                            gap: '100px',
                          }}
                        >
                          <Grid
                            sx={{
                              display: 'flex',
                              gap: '20px',
                              flexGrow: '1',
                            }}
                          >
                            <Box>
                              <Box
                                sx={{
                                  textAlign: 'center',
                                  width: { xs: '60px', md: '80px' },
                                  height: { xs: '60px', md: '80px' },
                                  backgroundColor: '#EFCC0D',
                                  marginBottom: '20px',
                                  position: 'relative',
                                  borderRadius: StepLabelBorderRadius,
                                  boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.4)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  '&::before': {
                                    //tiny triangle at the bottom of a StepLabel
                                    content: '""',
                                    position: 'absolute',
                                    top: '99%',
                                    left: '50%',
                                    marginLeft: `-${StepLabelTriangleSize}`,
                                    width: '0',
                                    height: '0',
                                    borderBottom: `solid ${StepLabelTriangleSize} transparent`,
                                    borderTop: `solid ${StepLabelTriangleSize} ${'#EFCC0D'}`,
                                    borderLeft: `solid ${StepLabelTriangleSize} transparent`,
                                    borderRight: `solid ${StepLabelTriangleSize} transparent`,
                                  },
                                }}
                              >
                                {slide?.Step && (
                                  <Typography
                                    sx={{
                                      fontSize: { xs: 30, md: 40 },
                                      fontWeight: 700,
                                      color: `${theme.palette.grey[500]}`,
                                    }}
                                  >
                                    {' '}
                                    {slide?.Step}
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                className="slider_card_box"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop: '-14px',
                                  position: 'relative',
                                  '&::before': {
                                    //tiny circle at the bottom of a list
                                    content: '""',
                                    position: 'absolute',
                                    backgroundColor: '#EFCC0D',
                                    bottom: '-120px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '100%',
                                    display: { xs: 'none', md: 'block' },
                                  },
                                }}
                              >
                                <img
                                  className="vertical-img"
                                  src="/images/lineimag.svg"
                                  alt="vertical line"
                                />
                              </Box>
                            </Box>
                            <Grid sx={{ 
                              flexGrow: '1',
                            }}>
                              {slide?.Title && (
                                <Typography
                                  sx={{
                                    fontSize: 30,
                                    color: '#EFCC0D',
                                    paddingTop: '20px',
                                    paddingBottom: { md: '70px', xs: '46px' },
                                    fontWeight: 900,
                                  }}
                                >
                                  {slide?.Title}
                                </Typography>
                              )}
                              {slide?.List?.length > 0 && (
                                <Grid
                                  className="list-item"
                                  container
                                  sx={{
                                    textAlign: 'left',
                                    fontSize: 16,
                                    flexDirection: 'column',
                                    marginLeft: { xs: '-20px' },
                                  }}
                                >
                                  {slide?.List?.map((item, itemIndex) => (
                                    <Grid item className="lists" sx={{ flexGrow: '1' }} key={'item' + itemIndex}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          gap: '0',
                                          alignItems: 'center',
                                          padding: '12px 5px',
                                          position: 'relative',
                                          '&::before': {
                                            //border bottom
                                            content: '""',
                                            position: 'absolute',
                                            bottom: '0',
                                            width: '100%',
                                            height: '100%',
                                            borderBottom: 'solid 1px rgba(255, 255, 255);',
                                            opacity: '25%',
                                            color: 'white',
                                            zIndex: '-1',
                                          },
                                          '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            bottom: '1px',
                                            width: '100%',
                                            height: '100%',
                                            borderBottom: 'solid 1px rgba(0, 0, 0);',
                                            opacity: '25%',
                                            color: 'white',
                                            zIndex: '-1',
                                          },
                                        }}
                                      >
                                        {/* popup */}
                                        {item?.PopupData != null && item?.PopupData?.ShowPopUp ? (
                                          <Box
                                            sx={{
                                              minWidth: '20px',
                                              height: '20px',
                                              backgroundColor: `${theme.palette.success.main}`,
                                              borderRadius: '25px',
                                              objectFit: 'contained',
                                              cursor: 'pointer',
                                              marginRight: '15px',

                                              '&:hover': {
                                                backgroundColor: `${theme.palette.primary.main}`,
                                                transition: `all 0.3s ease-in-out`,
                                              },
                                            }}
                                            onClick={() => HandleModal(slideIndex, itemIndex)}
                                          >
                                            <img
                                              className=""
                                              src="/images/question-circle.svg"
                                              alt="vertical line"
                                              width={20}
                                              height={20}
                                            />
                                          </Box>
                                        ) : (
                                          <Box
                                            sx={{
                                              minWidth: '10px',
                                              minHeight: '10px',
                                              borderRadius: '25px',
                                              backgroundColor: `${theme.palette.success.main}`,
                                              marginRight: '20px',
                                              marginLeft: '5px',
                                            }}
                                          ></Box>
                                        )}

                                        {item?.Heading && (
                                          <Typography
                                            sx={{
                                              fontSize: 16,
                                            }}
                                          >
                                            {item?.Heading}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          {/* second item */}
                        </Grid>
                      </div>
                    ))}
                  </Slider>
                </Box>                
              )
            }
          </Box>
          <Grid className="d-none">
            <Divider
              sx={{
                width: '100%',
                borderTop: '1px solid rgb(0, 0, 0, 0.2)',
                borderBottom: '1px solid rgba(255,255,255,20%)',
              }}
            />
          </Grid>
          {/* modal */}
          {modalStatus && <NewTextModal content={modalContent} handleClose={() => setModalStatus(false)} />}
        </Box>
      </Layout>
    </>
  )
}

export default Roadmap

export const query = graphql`
  query RoadmapPage {
    strapiGlobal(locale: { eq: "en" }) {
      ...GlobalData
    }
    strapiRoadmap {
      MetaData {
        MetaTitle
        MetaDescription    
      }
      SubHeading
      Heading
      Description {
        data {
          Description
        }
      }
      Slide {
        Step
        Title
        List {
          Heading
          PopupData {
            ShowPopUp
            Heading
            Content {
              data {
                Content
              }
            }
          }
        }
      }
    }
  }
`
